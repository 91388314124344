import appPages from "./app-pages";

export default [
  {
    key: "ManageSchool",
    element: <appPages.ManageSchoolComponent />,
    path: "/manage-school",
    // exact: true
  },
  {
    key: "CreateSchoolAdmin",
    element: <appPages.SchoolAdminComponent />,

    path: "/create-school-admin",
    exact: true,
  },

  {
    key: "athletes",
    element: <appPages.homePage />,
    path: "/athletes",
    exact: true,
  },
  {
    key: "playerList",
    element: <appPages.PlayerListIndex />,
    path: "/player-list",
    exact: true,
  },
  {
    key: "playerInvite",
    element: <appPages.PlayerInvite />,
    path: "/player-invite",
    exact: true,
  },
  {
    key: "playerInvite",
    element: <appPages.PlayerInvite />,
    path: "/player-Invite",
    exact: true,
  },
  {
    key: "CoachEvaluation",
    element: <appPages.CoachEvaluation />,
    path: "/coaches-evaluation",
  },
  {
    key: "ManagePlaylist",
    element: <appPages.ManagePlaylist />,
    path: "/manage-playlist",
  },
  {
    key: "CoachesNoteScreen",
    element: <appPages.CoachesNoteScreen />,
    path: "/coaches-note",
  },
  {
    key: "SchoolAdminsUpdate",
    element: <appPages.SchoolAdminUpdateComponent />,
    path: "/schools-update",
    exact: true,
  },
  {
    key: "AllGamesList",
    element: <appPages.AllGamesList />,
    path: "/all-games-list",
    exact: true,
  },
  {
    key: "seasonsList",
    element: <appPages.CoachAtheletesListComponent />,
    path: "/season-plays-list",
    exact: true,
  },
  {
    key: "CoachList",
    element: <appPages.CoachesListComponent />,

    path: "/coach-list",
    exact: true,
  },

  //   dashboard component
  {
    key: "SeasonAnalyticDashboard",
    element: <appPages.SeasonAnalyticDashboard />,
    path: "/season-analytic-dashboard",
    exact: true,
  },
  {
    key: "GameAnalyticsDashboard",
    element: <appPages.GameAnalyticsDashboard />,
    path: "/game-analytic-dashboard",
    exact: true,
  },
  {
    key: "stats-view",
    element: <appPages.Stats />,
    path: "/stats-view",
    exact: true,
  },
  {
    key: "add-stats",
    element: <appPages.CustomStats />,
    path: "/add-stats",
    exact: true,
  },
  {
    key: "PlayerStats",
    element: <appPages.PlayerStats />,
    path: "/player-stats-view",
    exact: true,
  },
  {
    key: "FormationAnalytics",
    element: <appPages.FormationAnalytics />,
    path: "/formation-analytic-dashboard",
    exact: true,
  },
  // wardbord screen
  {
    key: "wardbordscreen",
    element: <appPages.WardbordScreen />,
    path: "/wardbord",
    exact: true,
  },
  // super admin screen
  {
    key: "Teams",
    element: <appPages.Teams />,
    path: "/teams",
    exact: true,
  },
  {
    key: "Coach",
    element: <appPages.Coach />,
    path: "/coaches-view",
    exact: true,
  },
  {
    key: "Plans",
    element: <appPages.Plans />,
    path: "/plans",
    exact: true,
  },
  {
    key: "Dashboard",
    element: <appPages.Dashboard />,
    path: "/dashboard-view",
    exact: true,
  },
  {
    key: "PaymentPage",
    element: <appPages.PaymentPage />,
    path: "/payment",
  },
  {
    key: "SubscriptionPage",
    element: <appPages.SubscriptionPage />,
    path: "/subscription",
  },
  {
    key:"AffiliateReferrals",
    element: <appPages.AffiliateReferrals />,
    path: "/affiliate-referrals",
  }
];
