import appPages from "./app-pages";

export default [
  {
    key: "gamesList",
    element: <appPages.CoachGamesListComponent />,
    path: "/season-games-list",
    exact: true,
  },
  {
    key: "athletes",
    element: <appPages.homePage />,
    path: "/athletes",
    exact: true,
  },
  {
    key: "playerList",
    element: <appPages.PlayerListIndex />,
    path: "/player-list",
    exact: true,
  },
  {
    key: "playerInvite",
    element: <appPages.PlayerInvite />,
    path: "/player-invite",
    exact: true,
  },
  {
    key: "seasonsList",
    element: <appPages.CoachAtheletesListComponent />,
    path: "/season-plays-list",
    exact: true,
  },
  {
    key: "ChangeStatus",
    element: <appPages.ChangeStatus />,
    path: "/change-status",
  },
  {
    key: "CreateInvitePlayers",
    element: <appPages.CreateInvitePlayers />,
    path: "/create-invite-players",
  },
  {
    key: "CoachEvaluation",
    element: <appPages.CoachEvaluation />,
    path: "/coaches-evaluation",
  },
  {
    key: "ManagePlaylist",
    element: <appPages.ManagePlaylist />,
    path: "/manage-playlist",
  },
  {
    key: "CoachesNoteScreen",
    element: <appPages.CoachesNoteScreen />,
    path: "/coaches-note",
  },
  {
    key: "SchoolsList",
    element: <appPages.SchoolsListComponent />,
    path: "/schools-list",
    exact: true,
  },
  {
    key: "CoachSchedule",
    element: <appPages.CoachSchedule />,
    path: "/coach-schedule",
  },
  {
    key: "CoachWardbord",
    element: <appPages.CoachWardbord />,
    path: "/coach-wardbord",
  },
  {
    key: "ReportViewScreen",
    element: <appPages.ReportViewScreen />,
    path: "/report-view",
    exact: true,
  },
  {
    key: "mvpsList",
    element: <appPages.MvpsList />,
    path: "/mvps-list",
    exact: true,
  },
  {
    key: "CreateMvps",
    element: <appPages.MvpsCreate />,
    path: "/create-mvps",
    exact: true,
  },
  {
    key: "MvpsView",
    element: <appPages.MvpsViewModalComponent />,
    path: "/mvps-view",
    exact: true,
  },

  {
    key: "EvaluvationReportViewScreen",
    element: <appPages.EvaluvationReportViewScreen />,
    path: "/evaluvation-report",
    exact: true,
  },
  {
    key: "OverallGradeViewScreen",
    element: <appPages.OverallGradeViewScreen />,
    path: "/overall-grade",
    exact: true,
  },

  //   dashboard component
  {
    key: "SeasonAnalyticDashboard",
    element: <appPages.SeasonAnalyticDashboard />,
    path: "/season-analytic-dashboard",
    exact: true,
  },
  {
    key: "GameAnalyticsDashboard",
    element: <appPages.GameAnalyticsDashboard />,
    path: "/game-analytic-dashboard",
    exact: true,
  },
  {
    key: "stats-view",
    element: <appPages.Stats />,
    path: "/stats-view",
    exact: true,
  },
  {
    key: "add-stats",
    element: <appPages.CustomStats />,
    path: "/add-stats",
    exact: true,
  },

  {
    key: "PlayerStats",
    element: <appPages.PlayerStats />,
    path: "/player-stats-view",
    exact: true,
  },
  {
    key: "Scoring",
    element: <appPages.Scoring />,
    path: "/scoring",
    exact: true,
  },
  {
    key: "Leaderboard",
    element: <appPages.Leaderboard />,
    path: "/leaderboard",
    exact: true,
  },
  {
    key: "LeaderboardPrint",
    element: <appPages.LeaderboardPrint />,
    path: "/leaderboard-print",
    exact: true,
  },
  {
    key: "stats-to-print",
    element: <appPages.StatsToPrint />,
    path: "/stats-to-print",
    // exact: true,
  },

  {
    key: "FormationAnalytics",
    element: <appPages.FormationAnalytics />,
    path: "/formation-analytic-dashboard",
    exact: true,
  },
  {
    key: "wardbordscreen",
    element: <appPages.WardbordScreen />,
    path: "/wardbord",
    exact: true,
  },
];
