import loginPage from "../../containers/login/login";
import registerPage from "../../containers/register";
import registerSuccessful from "../../containers/register/register-successful";
import ContinueRegistration from "../../containers/register/continue-registration";
import homePage from "../../containers/school-admin/home-page";
import PaymentPage from "../../containers/school-admin/settings/paymentPage";
import ForgotPassword from "../../containers/login/forgot-password";
import SchoolsListComponent from "../../containers/school-admin/schools/schools-list";
import SchoolsViewComponent from "../../containers/school-admin/schools/schools-view";
import SchoolUpdateComponent from "../../containers/school-admin/schools/schools-update";
import SchoolsUpdatePhoneComponent from "../../containers/school-admin/schools/schools-update-phone";
import SchoolsUpdatePhotoModal from "../../containers/school-admin/schools/schools-update-photo";
import LibraryPageComponent from "../../containers/school-admin/library/library";
import CreateCoachComponent from "../../containers/school-admin/coaches/create-coach";
import CoachesListComponent from "../../containers/school-admin/coaches/coaches-list";
import ChangeStatus from "../../containers/school-admin/coaches/change-status";
import PlaysCreateComponent from "../../containers/school-admin/plays/create-plays";
import GamesCreateComponent from "../../containers/school-admin/games/create-games";
import PlaysListComponent from "../../containers/school-admin/plays/plays-list";
import GamesListComponent from "../../containers/school-admin/games/games-list";
import CoachesUpdateComponent from "../../containers/school-admin/coaches/coach-update";
import CoachesUpdatePhoneComponent from "../../containers/school-admin/coaches/coach-update-phone";
import CoachesUpdatePhotoComponent from "../../containers/school-admin/coaches/coach-update-photo";
import ResetPasswordComponent from "../../containers/login/password-verification";
import ScheduleScreen from "../../containers/school-admin/schedule/schedule";
import ReportViewScreen from "../../containers/school-admin/report-view/report-view";
import EvaluvationReportViewScreen from "../../containers/school-admin/report-view/evaluvation-report";
import OverallGradeViewScreen from "../../containers/school-admin/report-view/overall-grade";
import PlayerListIndex from '../../containers/school-admin/home-page/PlayerIndex';
import PlayerInvite from '../../containers/school-admin/home-page/PlayerInvite';

//Coach Routes
import CoachGamesListComponent from "../../containers/coach/games/games-list";
import CoachAtheletesListComponent from "../../containers/coach/plays-atheletes/index";
import MvpsList from "../../containers/coach/mvps/mvps-list";
import MvpsCreate from "../../containers/coach/mvps/create-mvps";
import MvpsViewModalComponent from "../../containers/coach/mvps/mvps-view";
import CreateInvitePlayers from "../../containers/coach/invite-players/create-invite-players";
import CoachEvaluation from "../../containers/coach/evaluations/coach-evaluation";
import ManagePlaylist from "../../containers/coach/evaluations/manage-playlist";
import CoachesNoteScreen from "../../containers/coach/evaluations/coaches-note";
import CoachSchedule from "../../containers/coach/coach-schedule/coach-schedule";
import CoachWardbord from "../../containers/coach/coach-wardbord/coach-wardbord";
import ManageSchoolComponent from "../../containers/admin/manage-school";
import SchoolAdminComponent from "../../containers/admin/create-school-admin";
import SchoolAdminUpdateComponent from "../../containers/admin/schools-update";
import AllGamesList from "../../containers/admin/games/games-list";
import CoachInvite from "../../containers/school-admin/coaches/coach-invite";

// dashboard routes
import SeasonAnalyticDashboard from "../../containers/Dashboard/Index";
import GameAnalyticsDashboard from "../../containers/Dashboard/GameAnalyticsDashboard";
import PlayerAnalyticsDashboard from "../../containers/Dashboard/PlayerAnalyticsDashboard";
import Stats from "../../containers/Dashboard/Stats";
import StatsToPrint from "../../containers/Dashboard/StatsToPrint";
import CustomStats from "../../containers/Dashboard/CustomStats";
import PlayerStats from "../../containers/Dashboard/PlayerStats";
import Scoring from "../../containers/Dashboard/Scoring";
import Leaderboard from "../../containers/Dashboard/Leaderboard/Leaderboard";
import LeaderboardPrint from "../../containers/Dashboard/Leaderboard/LeaderboardPrint";

// weardbord screen
import WardbordScreen from "../../containers/Wardbord/WardboardScreen";
import FormationAnalytics from "../../containers/Dashboard/FormationAnalytics";

// super admin screens 
import Teams from "../../containers/super-admin/Teams";
import Coach from "../../containers/super-admin/Coach";
import Plans from "../../containers/super-admin/Plans";
import Dashboard from "../../containers/super-admin/Dashboard";
import AffiliateReferrals from "../../containers/super-admin/AffiliateReferrals";

//Express check out
import ExpressCheckout from "../../containers/express-checkout";
import PlayerSignup from "../../containers/player-signup";

//new payment page
import SubscriptionPage from "../../containers/school-admin/settings/SubscriptionPage";

import Landing from "../../containers/landing";



// defining app pages
export default {
  loginPage,
  registerPage,
  registerSuccessful,
  ContinueRegistration,
  homePage,
  PaymentPage,
  ForgotPassword,
  SchoolsListComponent,
  SchoolsViewComponent,
  SchoolUpdateComponent,
  
  SchoolsUpdatePhoneComponent,
  SchoolsUpdatePhotoModal,
  CoachesNoteScreen,
  LibraryPageComponent,
  CreateCoachComponent,
  CoachesListComponent,
  ChangeStatus,
  PlaysCreateComponent,
  GamesCreateComponent,
  PlaysListComponent,
  GamesListComponent,
  CoachesUpdateComponent,
  CoachesUpdatePhoneComponent,
  CoachesUpdatePhotoComponent,
  CoachGamesListComponent,
  ScheduleScreen,
  ReportViewScreen,
  EvaluvationReportViewScreen,
  OverallGradeViewScreen,
  CoachInvite,

  CoachAtheletesListComponent,
  ResetPasswordComponent,

  //player list
  PlayerListIndex,
  PlayerInvite,
  PlayerSignup,

  MvpsList,
  MvpsCreate,
  MvpsViewModalComponent,
  CreateInvitePlayers,
  CoachEvaluation,
  ManagePlaylist,
  CoachSchedule,
  CoachWardbord,
  ManageSchoolComponent,
  SchoolAdminComponent,
  SchoolAdminUpdateComponent,
  AllGamesList,

  // dashboard component
  SeasonAnalyticDashboard,
  GameAnalyticsDashboard,
  PlayerAnalyticsDashboard,
  FormationAnalytics,
  Stats,
  CustomStats,
  PlayerStats,
  Scoring,
  Leaderboard,
  LeaderboardPrint,
  StatsToPrint,

  // wardboard screen
  WardbordScreen,

  // super admin screens
  Teams,
  Coach,
  Plans,
  Dashboard,
  AffiliateReferrals,

  //Express check out
  ExpressCheckout,

  //new payment page
  SubscriptionPage,

  Landing
};
